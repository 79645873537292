import React from 'react';

const DeploydLogo = () => (
    <svg width="100%" height="100%" viewBox="0 0 52 56">
    <g id="HFW-0.3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Projects/#2-Code-setup-1st-time" transform="translate(-51.000000, -24.000000)" fill-rule="nonzero">
            <g id="Navbar">
                <g id="Logo-with-wordmark">
                    <g transform="translate(24.000000, 0.000000)">
                        <g id="Logo/Original">
                            <g transform="translate(27.000000, 24.500000)">
                                <g id="Logo">
                                    <g id="Group_15" transform="translate(2.767000, 14.253000)" fill="#031A6B">
                                        <path d="M23.233,13.963002 C23.1652043,13.963002 23.098615,13.9450666 23.04,13.911 L0.193,0.72 C0.0734946602,0.651047741 -0.000185512483,0.523577887 -0.000185512483,0.385607097 C-0.000185512483,0.247636307 0.0733474247,0.120125628 0.192814502,0.0511070971 C0.31228158,-0.0179114337 0.45949466,-0.0179522589 0.579,0.051 L23.426,13.242 C23.5780567,13.3290368 23.6524223,13.5075679 23.607118,13.6768138 C23.5618136,13.8460597 23.4082037,13.9635655 23.233,13.963002 L23.233,13.963002 Z" id="Path_47"></path>
                                    </g>
                                    <g id="Group_16" transform="translate(25.614000, 14.253000)" fill="#031A6B">
                                        <path d="M0.387,13.963002 C0.211796299,13.9635655 0.0581864251,13.8460597 0.0128820476,13.6768138 C-0.0324223298,13.5075679 0.0419432695,13.3290368 0.194,13.242 L23.041,0.051 C23.1605053,-0.0179522589 23.3077184,-0.0179114337 23.4271855,0.0511070971 C23.5466526,0.120125628 23.6201855,0.247636307 23.6201855,0.385607097 C23.6201855,0.523577887 23.5465053,0.651047741 23.427,0.72 L0.58,13.911 C0.521384977,13.9450666 0.454795689,13.963002 0.387,13.963002 L0.387,13.963002 Z" id="Path_48"></path>
                                    </g>
                                    <g id="Group_17" transform="translate(24.614000, 42.310000)" fill="#031A6B">
                                        <path d="M1.386,9.713 C1.28362643,9.713 1.18544582,9.67233226 1.11305678,9.59994322 C1.04066774,9.52755418 1,9.42937357 1,9.327 L1,0.386 C1.00000007,0.172818137 1.17281814,1.30149488e-07 1.386,1.30149488e-07 C1.59918186,1.30149488e-07 1.77199993,0.172818137 1.772,0.386 L1.772,9.327 C1.772,9.42937357 1.73133226,9.52755418 1.65894322,9.59994322 C1.58655418,9.67233226 1.48837357,9.713 1.386,9.713 Z" id="Path_49"></path>
                                    </g>
                                    <polygon id="Path_50" fill="#031A6B" points="39.259 20.023 26 12.368 12.741 20.023 26 27.678"></polygon>
                                    <polygon id="Path_51" fill="#1D84B5" points="26 43.12 39.37 35.463 39.37 20.144 26 27.801"></polygon>
                                    <polygon id="Path_52" fill="#75D3FF" points="12.631 20.116 12.631 35.434 26.001 43.091 26.001 27.773"></polygon>
                                    <g id="Group_18" transform="translate(1.840000, 0.000000)">
                                        <path d="M24.161,52.4560014 C24.0147299,52.4560014 23.8710332,52.4175092 23.744,52.345 L0.682,39.167 C0.420079475,39.017212 0.258,38.7387262 0.258,38.437 L0.258,14.277 C0.258686665,13.9756315 0.42038906,13.6976101 0.682,13.548 L23.744,0.369 C24.0024216,0.221449211 24.3195784,0.221449211 24.578,0.369 L47.64,13.547 C47.9019205,13.696788 48.064,13.9752738 48.064,14.277 L48.064,38.437 C48.064,38.7387262 47.9019205,39.017212 47.64,39.167 L24.578,52.345 C24.451163,52.4179859 24.307337,52.4560014 24.161,52.4560014 L24.161,52.4560014 Z M1.94,14.764 L1.94,37.949 L24.161,50.649 L46.382,37.949 L46.382,14.764 L24.161,2.064 L1.94,14.764 Z" id="Path_53" fill="#031A6B"></path>
                                        <path d="M24.16,0.515 C24.26139,0.515 24.3610011,0.541639871 24.449,0.592 L47.512,13.771 C47.6935349,13.874855 47.806,14.0678573 47.806,14.277 L47.806,38.437 C47.806,38.6461427 47.6935349,38.839145 47.512,38.943 L24.449,52.121 C24.2697469,52.2240176 24.0492531,52.2240176 23.87,52.121 L0.808,38.943 C0.626313504,38.8392977 0.514,38.6461988 0.514,38.437 L0.514,14.277 C0.514,14.0678573 0.626465093,13.874855 0.808,13.771 L23.87,0.593 C23.9579989,0.542639871 24.05761,0.51609988 24.159,0.516 M24.159,50.944 L24.414,50.798 L46.377,38.248 L46.637,38.1 L46.637,14.614 L46.377,14.466 L24.414,1.916 L24.159,1.77 L23.904,1.916 L1.941,14.471 L1.682,14.619 L1.682,38.103 L1.941,38.251 L23.905,50.801 L24.16,50.947 M24.16,0.005 C23.9688195,0.00515146152 23.7809794,0.055127279 23.615,0.15 L0.553,13.328 C0.211003567,13.5235432 -0.000309816314,13.887048 -0.001,14.281 L-0.001,38.441 C-0.000309816314,38.834952 0.211003567,39.1984568 0.553,39.394 L23.615,52.571 C23.9527908,52.7636703 24.3672092,52.7636703 24.705,52.571 L47.767,39.393 C48.1089964,39.1974568 48.3203098,38.833952 48.321,38.44 L48.321,14.28 C48.3203098,13.886048 48.1089964,13.5225432 47.767,13.327 L24.705,0.149 C24.5390206,0.054127279 24.3511805,0.004 24.16,0.004 L24.16,0.005 Z M24.16,50.355 L2.196,37.805 L2.196,14.913 L24.16,2.363 L46.124,14.913 L46.124,37.799 L24.16,50.349 L24.16,50.355 Z" id="Path_54" fill="#FFFFFF"></path>
                                    </g>
                                    <circle id="Ellipse_47" fill="#031A6B" cx="3.205" cy="14.669" r="3.205"></circle>
                                    <circle id="Ellipse_48" fill="#031A6B" cx="48.795" cy="14.669" r="3.205"></circle>
                                    <circle id="Ellipse_49" fill="#031A6B" cx="26" cy="51.284" r="3.205"></circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default DeploydLogo;